
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import Toast from "primevue/toast";
import AppLoader from "@/components/common/AppLoader.vue";
import { DialogWrapper } from "vue3-promise-dialog";

export default defineComponent({
    name: "App",
    components: {
        Toast,
        AppLoader,
        DialogWrapper
    },
    setup() {
        const store = useStore();

        return {
            loading: computed(() => store.state.app.loading),
            me: computed(() => store.state.user.me),
            audiences: computed(() => store.state.audiences)
        };
    }
});
