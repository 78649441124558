import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState, AudienceState } from "../types";
import axios from "axios";
import AudienceModel from "@/models/AudienceModel";

export default {
    namespaced: true,
    state: () => ({
        list: []
    }),
    getters: {
        getDefault: state => () => {
            return state.list.find(audience => audience.default === true);
        }
    } as GetterTree<AudienceState, RootState>,
    mutations: {
        SET_AUDIENCES(state: AudienceState, list: AudienceModel[]) {
            state.list = list;
        }
    } as MutationTree<AudienceState>,
    actions: {
        fetchAudiences: ({ commit }) => {
            return axios.get("api/audiences").then(response => {
                commit("SET_AUDIENCES", Object.values(response.data.audiences));
                return response.data;
            });
        }
    } as ActionTree<AudienceState, RootState>
};
