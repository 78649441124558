import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LayoutDefault from "@/layouts/LayoutDefault.vue";
import LayoutBlank from "@/layouts/LayoutBlank.vue";
import store from "@/store";
import axiosConfig from "@/config/axios";
import axios from "axios";

const { state, commit, dispatch } = store;

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: LayoutDefault,
        children: [
            {
                path: "/",
                name: "dashboard",
                component: () => {
                    return state.auth.new_dashboard_enabled
                        ? import(
                              /* webpackChunkName: "dashboard" */ "@/views/NewDashboardPage.vue"
                          )
                        : import(
                              /* webpackChunkName: "dashboard" */ "@/views/DashboardPage.vue"
                          );
                },
                meta: {
                    title: "Dashboard - maatoo.io"
                }
            },
            {
                path: "/automations",
                name: "automations",
                component: () =>
                    import(
                        /* webpackChunkName: "automations" */ "@/views/AutomationsPage.vue"
                    ),
                children: [
                    {
                        path: ":id/activation",
                        name: "automation-activation",
                        component: () =>
                            import(
                                /* webpackChunkName: "automations" */ "@/views/automations/AutomationActivationPage.vue"
                            ),
                        meta: {
                            title: "Automations - maatoo.io"
                        }
                    },
                    {
                        path: "",
                        name: "automations-overview",
                        component: () =>
                            import(
                                /* webpackChunkName: "automations" */ "@/views/automations/AutomationsOverviewPage.vue"
                            ),
                        meta: {
                            title: "Automations - maatoo.io"
                        }
                    }
                ]
            },
            {
                path: "/mails",
                name: "mails",
                component: () =>
                    import(
                        /* webpackChunkName: "mails" */ "@/views/MailsPage.vue"
                    ),
                children: [
                    {
                        path: ":id/edit",
                        name: "mail-edit",
                        component: () =>
                            import(
                                /* webpackChunkName: "mails" */ "@/views/mails/MailEditPage.vue"
                            ),
                        meta: {
                            title: "Mail - maatoo.io"
                        }
                    },
                    {
                        path: ":id",
                        name: "mail-view",
                        component: () =>
                            import(
                                /* webpackChunkName: "mails" */ "@/views/mails/MailViewPage.vue"
                            ),
                        meta: {
                            title: "Mail - maatoo.io"
                        }
                    },
                    {
                        path: "",
                        name: "mails-list",
                        component: () =>
                            import(
                                /* webpackChunkName: "mails" */ "@/views/mails/MailsListPage.vue"
                            ),
                        meta: {
                            title: "Mails - maatoo.io"
                        }
                    }
                ]
            },
            {
                path: "/contacts",
                name: "contacts",
                component: () =>
                    import(
                        /* webpackChunkName: "contacts" */ "@/views/ContactsPage.vue"
                    ),
                children: [
                    {
                        path: "",
                        name: "contacts-list",
                        component: () =>
                            import(
                                /* webpackChunkName: "contacts" */ "@/views/contacts/ContactsListPage.vue"
                            ),
                        meta: {
                            title: "Contacts - maatoo.io"
                        }
                    }
                ]
            },
            {
                path: "/settings",
                name: "settings",
                component: () =>
                    import(
                        /* webpackChunkName: "settings" */ "@/views/SettingsPage.vue"
                    ),
                meta: {
                    title: "Brand - maatoo.io"
                },
                children: [
                    {
                        path: "",
                        alias: "brand",
                        name: "settings-brand",
                        component: () =>
                            import(
                                /* webpackChunkName: "settings" */ "@/views/settings/SettingsBrandPage.vue"
                            ),
                        meta: {
                            title: "Brand - maatoo.io"
                        }
                    },
                    {
                        path: "library",
                        name: "settings-library",
                        component: () =>
                            import(
                                /* webpackChunkName: "settings" */ "@/views/settings/SettingsLibraryPage.vue"
                            ),
                        meta: {
                            title: "Library - maatoo.io"
                        }
                    },
                    {
                        path: "legal",
                        name: "settings-legal",
                        component: () =>
                            import(
                                /* webpackChunkName: "settings" */ "@/views/settings/SettingsLegalPage.vue"
                            ),
                        meta: {
                            title: "Legal - maatoo.io"
                        }
                    },
                    {
                        path: "billing",
                        name: "settings-billing",
                        component: () =>
                            import(
                                /* webpackChunkName: "settings" */ "@/views/settings/SettingsBillingPage.vue"
                            ),
                        meta: {
                            title: "Billing - maatoo.io"
                        }
                    }
                ]
            }
        ]
    },
    {
        path: "/",
        component: LayoutBlank,
        children: [
            {
                path: "/error/:status",
                name: "error",
                component: () =>
                    import(
                        /* webpackChunkName: "error" */ "@/views/ErrorPage.vue"
                    ),
                meta: {
                    title: "Error - maatoo.io"
                }
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    }
});

router.beforeEach(async (to, from, next) => {
    const toPath = localStorage.getItem("to_path");

    if (to.name === "error") {
        next();
        return;
    }

    if (!state.app.initialized) {
        commit("app/SET_LOADING", true);

        // INIT AXIOS
        const axiosInit = await axiosConfig.init(to.path || "");

        if (axiosInit === "redirect") {
            next();
            return;
        }

        if (axiosInit === "code") {
            setTimeout(() => {
                window.history.replaceState(
                    {},
                    "Page",
                    process.env.VUE_APP_REDIRECT_URI ||
                        `${window.location.origin}/m/`
                );
            }, 100);
        }

        // Authorize
        await dispatch("user/fetchMe");
        window.analytics.identify(store.getters["user/emailHash"], {
            name: `${state.user.me.firstName} ${state.user.me.lastName}`,
            firstName: `${state.user.me.firstName}`,
            lastName: `${state.user.me.lastName}`,
            email: `${state.user.me.email}`,
            roleName: `${state.user.me.role.name}`,
            locale: `${localStorage.getItem("locale")}`
        });

        window.zESettings = {
            webWidget: {
                authenticate: {
                    chat: {
                        jwtFn: function(callback) {
                            axios.get("api/support-token").then(response => {
                                callback(response.data.token);
                            });
                        }
                    }
                }
            }
        };

        if (window.zE) {
            window.zE("webWidget", "prefill", {
                name: {
                    value: `${state.user.me.firstName} ${state.user.me.lastName}`,
                    readOnly: true
                },
                email: { value: `${state.user.me.email}`, readOnly: true }
            });
        }

        // INIT DATA
        const initRequests = [
            dispatch("stats/fetchStats"),
            dispatch("kpis/fetchKPIs"),
            dispatch("segments/fetchSegments"),
            dispatch("mails/fetchAddressList"),
            dispatch("audiences/fetchAudiences"),
            dispatch("auth/fetchClientConfig"),
            dispatch("store/fetchState"),
            dispatch("settings/fetchList")
        ];
        await Promise.allSettled(initRequests)
            .then(() => {
                // TODO handle rejected
                commit("app/SET_INITIALIZED", true);
            })
            .finally(() => {
                commit("app/SET_LOADING", false);
                window.analytics.identify(store.getters["user/emailHash"], {
                    release: store.state.auth.release
                });
            });
    }

    // TITLE SETUP
    document.title = `${to.meta.title}` || "maatoo.io";

    // CLEAR OVERFLOW
    document.body.classList.remove("overflow-hidden");

    if (toPath) {
        localStorage.removeItem("to_path");
        next(toPath);
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    window.analytics.page();
    if (from.name === "automation-activation") {
        store.commit("automations/CLEAR_ACTIVE");
        store.commit("mails/CLEAR_ACTIVE");
    }
});

export default router;
