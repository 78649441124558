
import { defineComponent } from "vue";
import HeaderDefault from "@/layouts/partials/HeaderDefault.vue";

export default defineComponent({
    name: "LayoutDefault",
    components: {
        HeaderDefault
    }
});
