import { createI18n } from "vue-i18n";
import messages from "@/locales";
import moment from "moment";

const availableLocales = ["en", "de"];
const availableEditorLocales = new Map([
    ["en", "en-US"],
    ["de", "de-DE"]
]);

function getBrowserLocale(options = {}): string {
    const defaultOptions = { countryCodeOnly: false };
    const opt = { ...defaultOptions, ...options };
    const navigatorLocale =
        navigator.languages !== undefined
            ? navigator.languages[0]
            : navigator.language;
    if (!navigatorLocale) {
        return process.env.I18N_DEFAULT_LOCALE || "en";
    }
    return opt.countryCodeOnly
        ? navigatorLocale.trim().split(/[-_]/)[0]
        : navigatorLocale.trim();
}

let locale =
    localStorage.getItem("locale") ||
    getBrowserLocale({ countryCodeOnly: true });

if (!availableLocales.includes(locale)) {
    locale = process.env.I18N_DEFAULT_LOCALE || "en";
}

localStorage.setItem("locale", locale);
moment.locale(locale);

let editorLocale =
    localStorage.getItem("editorLocale") ||
    availableEditorLocales.get(locale) ||
    "en-US";

if (!availableEditorLocales.has(locale)) {
    console.log("fallback");
    editorLocale = process.env.I18N_DEFAULT_EDITOR_LOCALE || "en-US";
}

localStorage.setItem("editorLocale", editorLocale);

export default createI18n({
    locale,
    fallbackLocale: process.env.I18N_DEFAULT_FALLBACK_LOCALE || "en",
    messages
});
