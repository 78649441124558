import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState, SegmentsState } from "../types";
import axios from "axios";
import SegmentModel from "@/models/SegmentModel";

export default {
    namespaced: true,
    state: () => ({
        list: [],
        total: 0
    }),
    getters: {
        enabledUserList: state => {
            return state.list.filter(
                segment =>
                    segment.isPublished === true && segment.createdBy !== null
            );
        },
        enabledSystemList: state => {
            return state.list.filter(
                segment =>
                    segment.isPublished === true && segment.createdBy === null
            );
        }
    } as GetterTree<SegmentsState, RootState>,
    mutations: {
        SET_LIST(state: SegmentsState, list: SegmentModel[]) {
            state.list = list;
        },
        SET_TOTAL(state: SegmentsState, total: number) {
            state.total = total;
        }
    } as MutationTree<SegmentsState>,
    actions: {
        fetchSegments: ({ commit }) => {
            return axios.get("api/segments?published=1").then(response => {
                const segments: SegmentModel[] = Object.values(
                    response.data.lists
                );
                const filteredSegments = segments.filter(
                    (segment: SegmentModel) =>
                        !segment.alias.startsWith("automation-")
                );
                commit("SET_LIST", filteredSegments);
                commit("SET_TOTAL", response.data.total);
                return response.data;
            });
        }
    } as ActionTree<SegmentsState, RootState>
};
