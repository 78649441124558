
import md5 from "md5-ts";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppAvatar",
    props: {
        email: {
            required: true,
            type: String
        },
        size: {
            required: true,
            type: Number
        }
    },
    computed: {
        hash(): string {
            return md5(this.email);
        },
        retinaSize(): number {
            return this.size * 2;
        }
    }
});
