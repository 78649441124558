import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "flex justify-center items-center p-1 cursor-pointer rounded-r-md bg-light-2 border border-light-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_avatar = _resolveComponent("app-avatar")!
  const _component_dropdown_button = _resolveComponent("dropdown-button")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_dropdown_base = _resolveComponent("dropdown-base")!

  return (_openBlock(), _createBlock(_component_dropdown_base, {
    open: _ctx.isOpen,
    "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOpen) = $event))
  }, {
    head: _withCtx(() => [
      (_ctx.type === 'avatar')
        ? (_openBlock(), _createBlock(_component_app_avatar, {
            key: 0,
            email: _ctx.me.email,
            size: 30,
            class: "cursor-pointer"
          }, null, 8, ["email"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'button')
        ? (_openBlock(), _createBlock(_component_dropdown_button, {
            key: 1,
            label: _ctx.label,
            open: _ctx.isOpen
          }, null, 8, ["label", "open"]))
        : _createCommentVNode("", true),
      (_ctx.type === 'contextmenu')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_app_icon, { name: "ChevronDown" })
          ]))
        : _createCommentVNode("", true)
    ]),
    body: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["open"]))
}