
import { defineComponent } from "vue";
import HeaderBlank from "@/layouts/partials/HeaderBlank.vue";

export default defineComponent({
    name: "LayoutBlank",
    components: {
        HeaderBlank
    }
});
