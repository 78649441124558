import { ActionTree, GetterTree, MutationTree } from "vuex";
import MailModel from "@/models/MailModel";
import { RootState, MailsState } from "../types";
import axios from "axios";
import moment from "moment";

export default {
    namespaced: true,
    state: () => ({
        list: [],
        active: null,
        addressList: [],
        total: 0
    }),
    getters: {
        getById: state => (id: number) => {
            return state.list.find(email => email.id === id);
        },
        activeStatus(state) {
            if (!state.active) {
                return "";
            }
            const upDate = moment(state.active.publishUp).valueOf();
            const downDate = moment(state.active.publishDown).valueOf();
            const now = moment().valueOf();
            const weekAgo = moment()
                .subtract(7, "d")
                .valueOf();

            if (
                state.active.emailType === "list" &&
                ((state.active.sentCount > 0 &&
                    upDate < now &&
                    downDate >= now) ||
                    (state.active.sentCount > 0 &&
                        upDate < now &&
                        downDate >= weekAgo) ||
                    (state.active.sentCount > 0 &&
                        state.active.isPublished &&
                        !state.active.publishUp &&
                        !state.active.publishDown) ||
                    (state.active.sentCount > 0 &&
                        state.active.isPublished &&
                        upDate < now &&
                        !state.active.publishDown) ||
                    (state.active.sentCount == 0 &&
                        state.active.isPublished &&
                        upDate <= now &&
                        downDate >= weekAgo))
            ) {
                return "active";
            }

            if (
                state.active.emailType === "list" &&
                ((state.active.sentCount > 0 &&
                    upDate < now &&
                    downDate < weekAgo) ||
                    (state.active.sentCount > 0 &&
                        !state.active.isPublished &&
                        !state.active.publishUp &&
                        !state.active.publishDown))
            ) {
                return "past";
            }

            if (
                state.active.emailType === "list" &&
                state.active.sentCount == 0 &&
                (!state.active.isPublished ||
                    (state.active.sentCount == 0 &&
                        state.active.isPublished &&
                        !state.active.publishUp &&
                        !state.active.publishDown))
            ) {
                return "draft";
            }

            if (
                state.active.emailType === "list" &&
                state.active.isPublished &&
                state.active.sentCount == 0 &&
                upDate > now
            ) {
                return "scheduled";
            }
            return "";
        },
        // Mail States: https://inspiratio.atlassian.net/wiki/spaces/MA/pages/2001502209/007+-+Mail+Handling
        activeList(state) {
            return state.list
                .filter(mail => {
                    const upDate = moment(mail.publishUp).valueOf();
                    const downDate = moment(mail.publishDown).valueOf();
                    const now = moment().valueOf();
                    const weekAgo = moment()
                        .subtract(7, "d")
                        .valueOf();
                    return (
                        mail.emailType === "list" &&
                        ((mail.sentCount > 0 &&
                            upDate < now &&
                            downDate >= now) ||
                            (mail.sentCount > 0 &&
                                upDate < now &&
                                downDate >= weekAgo) ||
                            (mail.sentCount > 0 &&
                                mail.isPublished &&
                                !mail.publishUp &&
                                !mail.publishDown) ||
                            (mail.sentCount > 0 &&
                                mail.isPublished &&
                                upDate < now &&
                                !mail.publishDown) ||
                            (mail.sentCount == 0 &&
                                mail.isPublished &&
                                upDate <= now &&
                                downDate >= weekAgo))
                    );
                })
                .sort(
                    (a, b) =>
                        moment(b.publishUp).valueOf() -
                        moment(a.publishUp).valueOf()
                );
        },
        scheduledList(state) {
            return state.list
                .filter(mail => {
                    const upDate = moment(mail.publishUp).valueOf();
                    const now = moment().valueOf();
                    return (
                        mail.emailType === "list" &&
                        mail.isPublished &&
                        mail.sentCount == 0 &&
                        upDate > now
                    );
                })
                .sort(
                    (a, b) =>
                        moment(b.publishUp).valueOf() -
                        moment(a.publishUp).valueOf()
                );
        },
        pastList(state) {
            return state.list
                .filter(mail => {
                    const upDate = moment(mail.publishUp).valueOf();
                    const downDate = moment(mail.publishDown).valueOf();
                    const now = moment().valueOf();
                    const weekAgo = moment()
                        .subtract(7, "d")
                        .valueOf();
                    return (
                        mail.emailType === "list" &&
                        ((mail.sentCount > 0 &&
                            upDate < now &&
                            downDate < weekAgo) ||
                            (mail.sentCount > 0 &&
                                !mail.isPublished &&
                                !mail.publishUp &&
                                !mail.publishDown))
                    );
                })
                .sort((a, b) => {
                    const aUpDate =
                        a.publishUp !== null
                            ? moment(a.publishUp).valueOf()
                            : Infinity;
                    const bUpDate =
                        b.publishUp !== null
                            ? moment(b.publishUp).valueOf()
                            : -Infinity;
                    return bUpDate - aUpDate;
                });
        },
        draftsList(state) {
            return state.list
                .filter(mail => {
                    return (
                        mail.emailType === "list" &&
                        mail.sentCount == 0 &&
                        (!mail.isPublished ||
                            (mail.sentCount == 0 &&
                                mail.isPublished &&
                                !mail.publishUp &&
                                !mail.publishDown))
                    );
                })
                .sort(
                    (a, b) =>
                        moment(b.dateModified).valueOf() -
                        moment(a.dateModified).valueOf()
                );
        }
    } as GetterTree<MailsState, RootState>,
    mutations: {
        SET_LIST(state: MailsState, list: MailModel[]) {
            state.list = list;
        },
        SET_TOTAL(state: MailsState, total: number) {
            state.total = total;
        },
        SET_ACTIVE(state: MailsState, mail: MailModel | null) {
            state.active = mail;
        },
        SET_ACTIVE_BY_ID(state: MailsState, id: number) {
            const mail = state.list.find(m => m.id === id);
            if (mail !== undefined) {
                state.active = { ...mail };
            }
        },
        SET_ACTIVE_NEW(state: MailsState) {
            const date = moment().format("YYYY-MM-DD HH:mm");
            state.active = {
                id: 0,
                isPublished: false,
                emailType: "list",
                name: `New Mail ${date}`,
                sentCount: 0,
                subject: "Blank",
                preheaderText: "",
                lists: [],
                fromName: null,
                fromAddress: null,
                beeTemplate: null,
                customHtml: "",
                utmTags: {
                    utmCampaign: `New Mail ${date}`,
                    utmContent: null,
                    utmMedium: "email",
                    utmSource: "maatoo"
                }
            } as MailModel;
        },
        CLEAR_ACTIVE(state: MailsState) {
            state.active = null;
        },
        SET_DUPLICATE(state: MailsState) {
            state.active = {
                ...state.active,
                id: 0,
                publishUp: null,
                publishDown: null,
                isPublished: false,
                plainText: null
            } as MailModel;
        },
        SET_ADDRESS_LIST(state: MailsState, addressList) {
            state.addressList = addressList;
        }
    } as MutationTree<MailsState>,
    actions: {
        fetchList: ({ commit }) => {
            return axios
                .get("api/emails?limit=0&orderBy=dateModified&orderByDir=desc")
                .then(response => {
                    const emails: MailModel[] = Object.values(
                        response.data.emails
                    );
                    const emailList = emails.map((email: MailModel) => {
                        const obj = Object.assign({}, email);
                        if (null !== obj.translationParent) {
                            email.segmentTranslationParent =
                                obj.translationParent?.id;
                        }
                        return email;
                    });
                    commit("SET_LIST", emailList);
                    commit("SET_TOTAL", response.data.total);
                    return response.data;
                });
            // @TODO: Handle error case when we can't fetch the emails for any reason
        },
        fetchActive: ({ commit }, id: string | number) => {
            return axios.get(`api/emails/${id}`).then(response => {
                const email: MailModel = response.data.email;
                if (null !== email.translationParent) {
                    email.segmentTranslationParent =
                        email.translationParent?.id;
                }
                commit("SET_ACTIVE", response.data.email);
                return response.data;
            });
            // @TODO: Handle error
        },
        fetchAddressList: ({ commit }) => {
            return axios
                .get("/api/validated-email-addresses")
                .then(response => {
                    commit("SET_ADDRESS_LIST", response.data.email_addresses);
                    return response.data;
                });
            // @TODO: Handle error
        },
        editActive: ({ commit }, mail: MailModel) => {
            return axios
                .put(`api/emails/${mail.id}/edit`, mail)
                .then(response => {
                    commit("SET_ACTIVE", response.data.email);
                    return response;
                });
            // @TODO: Handle error
        },
        deleteById: ({ commit }, id: number) => {
            return axios.delete(`api/emails/${id}/delete`).then(response => {
                return response;
            });
            // @TODO: Handle error
        },
        archiveById: ({ commit }, id: number) => {
            return axios.delete(`api/emails/${id}/delete`).then(response => {
                return response;
            });
            // @TODO: Handle error
        }
    } as ActionTree<MailsState, RootState>
};
