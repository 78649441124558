import { ActionTree, MutationTree } from "vuex";
import { RootState, RevenueState } from "../types";
import axios from "axios";
import moment from "moment";

type RevenueObject = {
    is_fetched: boolean;
    totalRevenue: number | string;
    netRevenue: number | string;
    automationRevenue: number | string;
    conversions: number | string;
};

type RevenueTimeSeries = { [date: string]: RevenueObject };

interface FetchGraphDataInterface {
    period?: number;
}

export default {
    namespaced: true,
    state: () => ({
        is_fetched: false,
        loading: false,
        total: [],
        net: [],
        automation: [],
        conversion: []
    }),
    mutations: {
        SET_ISFETCHED(state: RevenueState, is_fetched: boolean) {
            state.is_fetched = is_fetched;
        },
        SET_TOTAL(state: RevenueState, revenue: number[]) {
            state.total = revenue;
        },
        SET_NET(state: RevenueState, revenue: number[]) {
            state.net = revenue;
        },
        SET_AUTOMATION(state: RevenueState, revenue: number[]) {
            state.automation = revenue;
        },
        SET_CONVERSION(state: RevenueState, revenue: number[]) {
            state.conversion = revenue;
        },
        SET_LOADING(state: RevenueState, loading: boolean) {
            state.loading = loading;
        }
    } as MutationTree<RevenueState>,
    actions: {
        fetchGraphs: ({ commit }, params: FetchGraphDataInterface = {}) => {
            commit("SET_LOADING", true);
            return axios.get("api/graphs", { params }).then(response => {
                const is_fetched = true;
                const total = Object.entries(
                    response.data as RevenueTimeSeries
                ).map(([date, value]) => {
                    return {
                        x: moment.unix(parseInt(date)).toISOString(),
                        y:
                            typeof value.totalRevenue === "string"
                                ? parseInt(value.totalRevenue)
                                : value.totalRevenue
                    };
                });

                const net = Object.entries(
                    response.data as RevenueTimeSeries
                ).map(([date, value]) => {
                    return {
                        x: moment.unix(parseInt(date)).toISOString(),
                        y:
                            typeof value.netRevenue === "string"
                                ? parseInt(value.netRevenue)
                                : value.netRevenue
                    };
                });

                const automation = Object.entries(
                    response.data as RevenueTimeSeries
                ).map(([date, value]) => {
                    return {
                        x: moment.unix(parseInt(date)).toISOString(),
                        y:
                            typeof value.automationRevenue === "string"
                                ? parseInt(value.automationRevenue)
                                : value.automationRevenue
                    };
                });

                const conversion = Object.entries(
                    response.data as RevenueTimeSeries
                ).map(([date, value]) => {
                    return {
                        x: moment.unix(parseInt(date)).toISOString(),
                        y:
                            typeof value.conversions === "string"
                                ? parseInt(value.conversions)
                                : value.conversions
                    };
                });

                commit("SET_LOADING", false);
                commit("SET_ISFETCHED", is_fetched);
                commit("SET_TOTAL", total);
                commit("SET_NET", net);
                commit("SET_AUTOMATION", automation);
                commit("SET_CONVERSION", conversion);
                return response.data;
            });
        }
    } as ActionTree<RevenueState, RootState>
};
