import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState, StoreState } from "../types";
import axios from "axios";

export default {
    namespaced: true,
    state: () => ({
        currency: "CHF"
    }),
    getters: {} as GetterTree<StoreState, RootState>,
    mutations: {
        SET_STATE(state: StoreState, storeData: StoreState) {
            state.currency = storeData.currency;
        }
    } as MutationTree<StoreState>,
    actions: {
        fetchState: ({ commit }) => {
            return axios.get("api/stores").then(response => {
                if (response.data.total > 0) {
                    commit(
                        "SET_STATE",
                        response.data.stores[
                            Object.keys(response.data.stores)[0]
                        ]
                    );
                }
                return response.data;
            });
        }
    } as ActionTree<StoreState, RootState>
};
