import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-flex items-center justify-center" }
const _hoisted_2 = ["width", "height", "aria-labelledby"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: _ctx.width || _ctx.sizeInPx,
      height: _ctx.height || _ctx.sizeInPx,
      viewBox: "0 0 24 24",
      "aria-labelledby": _ctx.iconName,
      role: "presentation",
      style: {"pointer-events":"none"}
    }, [
      _createElementVNode("title", {
        id: _ctx.iconName,
        lang: "en"
      }, _toDisplayString(_ctx.iconName || _ctx.name) + " icon", 9, _hoisted_3),
      _createElementVNode("g", null, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconComponent)))
      ])
    ], 8, _hoisted_2))
  ]))
}