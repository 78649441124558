import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState, KPIState } from "../types";
import axios from "axios";
import KPIModel from "@/models/KPIModel";

interface FetchKPIDataInterface {
    start_date?: string;
    end_date?: string;
}

export default {
    namespaced: true,
    state: () => ({
        data: null,
        loading: false
    }),
    getters: {} as GetterTree<KPIState, RootState>,
    mutations: {
        SET_KPIS(state: KPIState, kpis: KPIModel) {
            state.data = kpis;
        },
        SET_LOADING(state: KPIState, loading: boolean) {
            state.loading = loading;
        }
    } as MutationTree<KPIState>,
    actions: {
        fetchKPIs: ({ commit }, params: FetchKPIDataInterface = {}) => {
            commit("SET_LOADING", true);
            return axios
                .get("api/ecommerce/kpis", { params })
                .then(response => {
                    commit("SET_KPIS", response.data.kpis);
                    commit("SET_LOADING", false);
                    return response.data;
                });
        }
    } as ActionTree<KPIState, RootState>
};
