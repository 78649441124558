import { createStore, StoreOptions } from "vuex";
import app from "./modules/app";
import auth from "./modules/auth";
import user from "./modules/user";
import mails from "./modules/mails";
import stats from "./modules/stats";
import kpis from "./modules/kpis";
import segments from "./modules/segments";
import automations from "./modules/automations";
import revenue from "./modules/revenue";
import dashboard from "./modules/dashboard";
import audiences from "./modules/audiences";
import buildertokens from "./modules/buildertokens";
import recommendations from "./modules/recommendations";
import store from "./modules/store";
import contacts from "./modules/contacts";
import templates from "@/store/modules/templates";
import settings from "@/store/modules/settings";
import rows from "@/store/modules/rows";
import { RootState } from "./types";

export const storeOptions: StoreOptions<RootState> = {
    modules: {
        app,
        auth,
        user,
        mails,
        stats,
        kpis,
        segments,
        automations,
        revenue,
        dashboard,
        audiences,
        buildertokens,
        recommendations,
        store,
        contacts,
        templates,
        settings,
        rows
    }
};
export default createStore(storeOptions);
