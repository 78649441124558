import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RowsState, RootState } from "@/store/types";
import RowModel from "@/models/RowModel";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import useUnicodeHelper from "@/composables/useUnicodeHelper";

const { decodeUnicode } = useUnicodeHelper();

export default {
    namespaced: true,
    state: () => ({
        list: [],
        active: null
    }),
    getters: {
        getList(state) {
            return state.list;
        }
    } as GetterTree<RowsState, RootState>,
    mutations: {
        SET_LIST(state: RowsState, list: RowModel[]) {
            state.list = list;
        },
        SET_ACTIVE(state: RowsState, row: RowModel | null) {
            state.active = row;
        },
        SET_ACTIVE_NEW(state: RowsState) {
            state.active = {
                id: 0,
                rowId: uuidv4(),
                rowJson: "",
                pageJson: "",
                rowHtml: ""
            } as RowModel;
        },
        CLEAR_ACTIVE(state: RowsState) {
            state.active = null;
        }
    } as MutationTree<RowsState>,
    actions: {
        fetchList: ({ commit }, params) => {
            return axios
                .get("api/rows", {
                    params: params
                })
                .then(response => {
                    const rows: RowModel[] = Object.values(response.data.rows);

                    const rowList = rows.map((row: RowModel) => {
                        const rowContent = decodeUnicode(row.rowJson);
                        if (rowContent) {
                            const rowJsonContent = JSON.parse(rowContent);
                            row.name = rowJsonContent.metadata.name;
                        }

                        const pageContent = decodeUnicode(row.pageJson);
                        row.pageContent = pageContent;

                        return row;
                    });

                    commit("SET_LIST", rowList);
                    return response.data;
                });
        },
        // eslint-disable-next-line
        deleteById: ({ commit, state }, id: number) => {
            const row = state.list.find((row: RowModel) => row.id === id);
            if (row) {
                return axios
                    .delete(`api/rows/${row.rowId}/delete`)
                    .then(response => {
                        return response;
                    });
            } else {
                return false;
            }
        },
        editActive: ({ commit }, row: RowModel) => {
            return axios.put(`api/rows/${row.id}/edit`, row).then(response => {
                commit("SET_ACTIVE", response.data.row);
                return response;
            });
            // @TODO: Handle error
        }
    } as ActionTree<RowsState, RootState>
};
