import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "absolute top-full mt-0.5 right-0 bg-white border border-light-3 rounded-md shadow-lg overflow-hidden z-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _renderSlot(_ctx.$slots, "head")
    ]),
    (_ctx.open)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "body")
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_click_outside, {
            handler: () => _ctx.close(),
            exclude: ['p-datepicker-prev', 'p-datepicker-next']
        }]
  ])
}