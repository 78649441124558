import { ActionTree, GetterTree, MutationTree } from "vuex";
import RecommendationModel from "@/models/RecommendationModel";
import { RootState, RecommendationState } from "../types";
import axios from "axios";

export default {
    namespaced: true,
    state: () => ({
        list: []
    }),
    getters: {
        sortedList(state) {
            return state.list
                .sort((a, b) => {
                    return a.dateAdded === b.dateAdded
                        ? 0
                        : a.dateAdded < b.dateAdded
                        ? 1
                        : -1;
                })
                .sort((a, b) => {
                    return a.isPublished === b.isPublished
                        ? 0
                        : a.isPublished < b.isPublished
                        ? 1
                        : -1;
                });
        }
    } as GetterTree<RecommendationState, RootState>,
    mutations: {
        SET_RECOMMENDATIONS(
            state: RecommendationState,
            list: RecommendationModel[]
        ) {
            state.list = list;
        },
        UPDATE_RECOMMENDATION(
            state: RecommendationState,
            recommendation: RecommendationModel
        ) {
            const index = state.list.findIndex(r => r.id === recommendation.id);
            if (index !== -1) {
                state.list[index] = recommendation;
            }
        }
    } as MutationTree<RecommendationState>,
    actions: {
        fetchList: ({ commit }) => {
            return axios.get("api/recommendations").then(response => {
                commit(
                    "SET_RECOMMENDATIONS",
                    Object.values(response.data.recommendations)
                );
                return response.data;
            });
        },
        markAsRead: ({ commit }, id: number | string) => {
            return axios
                .patch(`api/recommendations/${id}/edit`, {
                    isPublished: false
                })
                .then(response => {
                    commit(
                        "UPDATE_RECOMMENDATION",
                        response.data.recommendation
                    );
                    return response;
                });
        }
    } as ActionTree<RecommendationState, RootState>
};
