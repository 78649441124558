
import { useStore } from "vuex";
import { computed, defineComponent, ref } from "vue";
import AppButton from "@/components/common/AppButton.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import { useRoute, useRouter } from "vue-router";
import DropdownMenu from "@/components/common/dropdown/DropdownMenu.vue";
import DropdownMenuItem from "@/components/common/dropdown/DropdownMenuItem.vue";
import DropdownMenuDivider from "@/components/common/dropdown/DropdownMenuDivider.vue";
import goToSupport from "@/composables/goToSupport";

export default defineComponent({
    name: "HeaderDefault",
    components: {
        AppButton,
        AppIcon,
        DropdownMenu,
        DropdownMenuItem,
        DropdownMenuDivider
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const routes = [
            { to: "/", name: "dashboard" },
            { to: "/automations", name: "automations" },
            { to: "/mails", name: "mails" },
            { to: "/contacts", name: "contacts" }
        ];
        const { goToSupportPage, openChat } = goToSupport();

        const showMobileMenu = ref(false);
        const toggleShowMobileMenu = () => {
            showMobileMenu.value = !showMobileMenu.value;
        };

        const createNewMail = () => {
                store.commit("mails/SET_ACTIVE_NEW");
                router.push({
                    name: "mail-edit",
                    params: { id: 0 }
                });
            },
            switchToClassic = () => {
                store.dispatch("auth/switchToClassic");
            },
            signOut = () => {
                store.dispatch("auth/signOut");
                window.analytics.track("Signed Out", {
                    userId: store.getters["user/emailHash"]
                });
            },
            openSettings = () => {
                router.push({
                    name: "settings-brand"
                });
            };

        const isRouteActive = (name: string): boolean => {
            return route.matched.some(r => r.name === name);
        };

        return {
            isRouteActive,
            createNewMail,
            routes,
            switchToClassic,
            signOut,
            goToSupport,
            me: computed(() => store.state.user.me),
            showMobileMenu,
            toggleShowMobileMenu,
            goToSupportPage,
            openChat,
            openSettings
        };
    }
});
