import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative flex justify-between bg-white shadow h-17" }
const _hoisted_2 = { class: "flex items-center justify-start px-5" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "inline-block px-3",
        src: require('@/assets/img/logo.svg'),
        alt: "Logo"
      }, null, 8, _hoisted_3)
    ])
  ]))
}