import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState, SettingsState } from "../types";
import axios from "axios";
import SettingModel from "@/models/SettingModel";

export default {
    namespaced: true,
    state: () => ({
        list: [],
        total: 0
    }),
    getters: {
        getSettingByKey: state => (keyName: string) => {
            return state.list.find(setting => setting.key === keyName);
        },
        getList: state => state.list
    } as GetterTree<SettingsState, RootState>,
    mutations: {
        SET_LIST(state: SettingsState, data: SettingModel[]) {
            state.list = data;
        },
        SET_TOTAL(state: SettingsState, total: number) {
            state.total = total;
        }
    } as MutationTree<SettingsState>,
    actions: {
        fetchList: ({ commit }) => {
            return axios.get("api/settings").then(response => {
                commit("SET_LIST", Object.values(response.data.settings));
                commit("SET_TOTAL", response.data.total);
                return response.data;
            });
        },
        createBatch: (
            { commit },
            data: {
                key: number | string;
                value: number | string | object;
                type?: string;
            }[]
        ) => {
            return axios.post("api/settings/batch/new", data).then(response => {
                commit("SET_DATA", response.data);
            });
        },
        // eslint-disable-next-line
        createOrUpdateSetting: ({ commit }, setting: SettingModel) => {
            return axios
                .put(`api/settings/${setting.id}/edit`, setting)
                .then(response => {
                    return response;
                });
        },
        // eslint-disable-next-line
        removeSettingById: ({ commit }, id: number) => {
            return axios.delete(`api/settings/${id}/delete`).then(response => {
                return response;
            });
        }
    } as ActionTree<SettingsState, RootState>
};
