
import {
    defineComponent,
    defineAsyncComponent,
    ComponentPublicInstance
} from "vue";

export default defineComponent({
    name: "AppIcon",
    props: {
        name: {
            type: String,
            required: true
        },
        iconName: {
            type: String,
            default: ""
        },
        size: {
            type: [Number, String],
            default: 6
        },
        width: {
            type: [Number, String],
            default: 0
        },
        height: {
            type: [Number, String],
            default: 0
        }
    },
    computed: {
        iconComponent(): ComponentPublicInstance {
            return defineAsyncComponent(() =>
                import("@/components/icons/Icon" + this.name + ".vue")
            );
        },
        sizeInPx(): number {
            return typeof this.size === "string"
                ? parseInt(this.size) * 4
                : this.size * 4;
        }
    }
});
