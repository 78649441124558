import { ActionTree, GetterTree, MutationTree } from "vuex";
import { BuilderTokensState, RootState } from "../types";
import axios from "axios";
import MergeTagModel from "@/models/MergeTagModel";
import SpecialLinkModel from "@/models/SpecialLinkModel";

export default {
    namespaced: true,
    state: () => ({
        mergeTags: [],
        specialLinks: [],
        is_fetched: false
    }),
    getters: {
        getMergeTags(state) {
            return state.mergeTags;
        },
        getSpecialLinks(state) {
            return state.specialLinks;
        }
    } as GetterTree<BuilderTokensState, RootState>,
    mutations: {
        SET_MERGE_TAGS(state: BuilderTokensState, mergeTags: MergeTagModel[]) {
            state.mergeTags = mergeTags;
        },
        SET_SPECIAL_LINKS(
            state: BuilderTokensState,
            specialLinks: SpecialLinkModel[]
        ) {
            state.specialLinks = specialLinks;
        },
        SET_ISFETCHED(state: BuilderTokensState, is_fetched: boolean) {
            state.is_fetched = is_fetched;
        }
    } as MutationTree<BuilderTokensState>,
    actions: {
        fetchState: ({ commit }) => {
            return axios.get("/api/bee/builder-tags").then(response => {
                commit(
                    "SET_MERGE_TAGS",
                    Object.entries(response.data.tokens)
                        .filter(token => token[0].startsWith("{contactfield="))
                        .map(token => ({
                            name: token[1],
                            value: token[0]
                        }))
                );
                commit(
                    "SET_SPECIAL_LINKS",
                    Object.entries(response.data.tokens)
                        .filter(
                            token =>
                                token[0].startsWith("{pagelink=") ||
                                token[0].startsWith("{assetlink=") ||
                                token[0].startsWith("{webview_url") ||
                                token[0].startsWith("{unsubscribe_url") ||
                                token[0].startsWith("{resubscribe_url")
                        )
                        .map(token => ({
                            label: String(token[1]).replace("a:", ""),
                            link: token[0],
                            type: token[0].startsWith("{pagelink=")
                                ? "Landing Pages"
                                : token[0].startsWith("{assetlink=")
                                ? "Assets"
                                : "Email"
                        }))
                );
                commit("SET_ISFETCHED", true);
                return response.data;
            });
        }
    } as ActionTree<BuilderTokensState, RootState>
};
