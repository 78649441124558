import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-screen flex justify-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_loader = _resolveComponent("app-loader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_DialogWrapper = _resolveComponent("DialogWrapper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_app_loader)
        ]))
      : (_openBlock(), _createBlock(_component_router_view, { key: 1 })),
    _createVNode(_component_Toast, { position: "top-right" }),
    _createVNode(_component_DialogWrapper, { "transition-attrs": { name: 'dialog' } })
  ], 64))
}