import { ActionTree, GetterTree, MutationTree } from "vuex";
import { TemplatesState, RootState } from "@/store/types";
import TemplateModel from "@/models/TemplateModel";
import axios from "axios";
import moment from "moment";

export default {
    namespaced: true,
    state: () => ({
        list: [],
        active: null
    }),
    getters: {
        getList(state) {
            return state.list.sort(
                (a, b) =>
                    moment(b.dateAdded).valueOf() -
                    moment(a.dateAdded).valueOf()
            );
        },
        getUserTemplates(state) {
            return state.list
                .filter((template: TemplateModel) => {
                    return template.author !== "maatoo.io";
                })
                .sort(
                    (a, b) =>
                        moment(b.dateAdded).valueOf() -
                        moment(a.dateAdded).valueOf()
                );
        },
        getPublishedList(state) {
            return state.list.filter(template => template.isPublished === true);
        }
    } as GetterTree<TemplatesState, RootState>,
    mutations: {
        SET_LIST(state: TemplatesState, list: TemplateModel[]) {
            state.list = list;
        },
        SET_ACTIVE(state: TemplatesState, template: TemplateModel | null) {
            state.active = template;
        },
        SET_ACTIVE_NEW(state: TemplatesState) {
            state.active = {
                id: 0,
                title: `template_new_${moment().format("YYYY-MM-DD HH:mm")}`,
                content: "{}",
                preview: "",
                author: "",
                description: "",
                isPublished: true,
                dateAdded: moment().format("YYYY-MM-DD HH:mm")
            } as TemplateModel;
        },
        CLEAR_ACTIVE(state: TemplatesState) {
            state.active = null;
        }
    } as MutationTree<TemplatesState>,
    actions: {
        fetchList: ({ commit }, params) => {
            return axios
                .get("api/templates", {
                    params: params
                })
                .then(response => {
                    const templates: TemplateModel[] = Object.values(
                        response.data.templates
                    );

                    commit("SET_LIST", templates);
                    return response.data;
                });
        },
        // eslint-disable-next-line
        deleteById: ({ commit }, id: number) => {
            return axios.delete(`api/templates/${id}/delete`).then(response => {
                return response;
            });
        },
        editActive: ({ commit }, template: TemplateModel) => {
            return axios
                .put(`api/templates/${template.id}/edit`, template)
                .then(response => {
                    commit("SET_ACTIVE", response.data.email);
                    return response;
                });
            // @TODO: Handle error
        }
    } as ActionTree<TemplatesState, RootState>
};
