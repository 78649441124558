
import { defineComponent } from "vue";
import AppIcon from "@/components/common/AppIcon.vue";

export default defineComponent({
    name: "AppDropdown",
    components: {
        AppIcon
    },
    emits: ["toggle"],
    props: {
        open: {
            type: Boolean,
            required: true
        },
        label: {
            type: String,
            default: ""
        }
    }
});
