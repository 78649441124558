import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args))),
    class: _normalizeClass(
            `${_ctx.style} ${_ctx.sizes[_ctx.buttonSize]} ${
                _ctx.isBreadcrumb ? 'pr-5' : 'px-5'
            } focus:outline-none rounded-lg`
        )
  }, [
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "left"),
      _createElementVNode("span", {
        class: _normalizeClass(["inline-block", [_ctx.hasLeft ? 'ml-2' : '', _ctx.hasRight ? 'mr-2' : '']])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2),
      _renderSlot(_ctx.$slots, "right")
    ])
  ], 2))
}