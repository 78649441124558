
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppLoader",
    props: {
        width: {
            type: [Number, String],
            default: 75
        }
    }
});
