import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: 
            `https://www.gravatar.com/avatar/${_ctx.hash}?s=${_ctx.retinaSize}&d=identicon&r=g&default=mp`
        ,
    alt: "avatar",
    class: "rounded-full",
    width: _ctx.size,
    height: _ctx.size
  }, null, 8, _hoisted_1))
}