
import { defineComponent, inject } from "vue";

export default defineComponent({
    name: "DropdownMenuItem",
    props: {
        label: {
            type: String,
            required: true
        },
        altLabel: {
            type: String,
            required: false
        }
    },
    emits: ["click"],
    setup(props, { emit }) {
        const close = inject("close") as () => void;
        const handleClick = () => {
            close();
            emit("click");
        };

        return {
            handleClick
        };
    }
});
