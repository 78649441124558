import axios from "axios";
import store from "@/store";
import router from "@/router";
const { commit } = store;

export default {
    axios,
    init: async (toPath: string): Promise<"code" | "token" | "redirect"> => {
        axios.defaults.baseURL = process.env.VUE_APP_API_URL || "/";

        const ACCESS_TOKEN = localStorage.getItem("app_access_token");

        const getCode = (): string | null => {
            const uri = window.location.search.substring(1);
            const params = new URLSearchParams(uri);
            return params.get("code") || null;
        };
        const CODE = getCode();
        const setHeaderToken = (token: string) => {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        };
        const authorize = async () => {
            if (toPath !== "/") {
                localStorage.setItem("to_path", toPath);
            }
            axios.defaults.headers.common["Authorization"] = undefined;
            await store.dispatch("auth/authorize");
        };

        axios.interceptors.response.use(
            response => {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            },
            error => {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error

                // TODO cover all potential cases
                if (error.response && error.response.status === 500) {
                    store.commit("app/SET_LOADING", false);
                    commit(
                        "app/SET_PREVIOUS_PATH",
                        router.currentRoute.value.path
                    );
                    router.push({
                        name: "error",
                        params: {
                            status: 500
                        }
                    });
                }

                const {
                    config: originalRequest,
                    response: {
                        status,
                        data: {
                            errors: [{ message }]
                        }
                    }
                } = error;

                console.log("status", status);
                console.log("message", message);

                if (
                    status === 401 &&
                    message === "The access token provided has expired."
                ) {
                    // token expired
                    return new Promise((resolve, reject) => {
                        store
                            .dispatch("auth/refresh")
                            .then(token => {
                                setHeaderToken(token);
                                originalRequest.headers["Authorization"] =
                                    "Bearer " + token;
                                resolve(axios(originalRequest));
                            })
                            .catch(error => {
                                reject(error);
                            });
                    });
                }

                // Redirect for unauthorized user
                // status === 400 && message === "Refresh token has expired"
                // status === 400 && message === "Invalid refresh token"
                // status === 400 && message === "Code doesn't exist or is invalid for the client"
                // status === 401 && message === "The access token provided is invalid."
                // status === 401 && message === "API authorization denied."
                if (
                    (status === 400 &&
                        message === "Refresh token has expired") ||
                    (status === 400 && message === "Invalid refresh token") ||
                    (status === 400 &&
                        message ===
                            "Code doesn't exist or is invalid for the client") ||
                    (status === 401 &&
                        message === "The access token provided is invalid.") ||
                    (status === 401 && message === "API authorization denied.")
                ) {
                    authorize();
                }

                return Promise.reject(error);
            }
        );

        if (ACCESS_TOKEN) {
            setHeaderToken(ACCESS_TOKEN);
            return Promise.resolve("token");
        } else if (CODE) {
            const token = await store.dispatch("auth/fetchAccessToken", CODE);
            // TODO check user
            window.analytics.track("Signed in");
            setHeaderToken(token);
            return Promise.resolve("code");
        } else {
            await authorize();
            return Promise.resolve("redirect");
        }
    }
};
