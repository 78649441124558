import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState, StatsState } from "../types";
import axios from "axios";
import { StatsEmailModel } from "@/models/StatsModel";

export default {
    namespaced: true,
    state: () => ({
        automations: [],
        emails: []
    }),
    getters: {
        getStatsByEmailId: state => (id: number) => {
            return state.emails.find(email => email.id === id);
        },
        getStatsByAutomationId: state => (id: number) => {
            return state.automations.find(stat => stat.id === id);
        }
    } as GetterTree<StatsState, RootState>,
    mutations: {
        SET_AUTOMATIONS(state: StatsState, emails: StatsEmailModel[]) {
            state.automations = emails;
        },
        SET_EMAILS(state: StatsState, emails: StatsEmailModel[]) {
            state.emails = emails;
        }
    } as MutationTree<StatsState>,
    actions: {
        fetchStats: ({ commit }) => {
            return axios.get("api/ecommerce/stats").then(response => {
                if (response.data.stats.emails !== undefined) {
                    commit("SET_EMAILS", response.data.stats.emails);
                }
                if (response.data.stats.automations !== undefined) {
                    commit("SET_AUTOMATIONS", response.data.stats.automations);
                }
                return response.data;
            });
        }
    } as ActionTree<StatsState, RootState>
};
