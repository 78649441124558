
import { defineComponent, provide } from "vue";
import clickOutside from "@/directives/clickOutside";

export default defineComponent({
    name: "DropdownMenu",
    directives: {
        "click-outside": clickOutside
    },
    props: {
        open: { type: Boolean, default: false }
    },
    emits: ["update:open"],
    setup(props, { emit }) {
        const close = () => {
            emit("update:open", false);
        };
        const toggle = () => {
            emit("update:open", !props.open);
        };

        provide("close", close);

        return {
            toggle,
            close
        };
    }
});
