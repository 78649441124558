import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-sm font-medium text-dark-2 text-left group-hover:text-white" }
const _hoisted_2 = {
  key: 0,
  class: "text-xs text-dark-3 group-hover:text-light-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "leading-9 px-4 cursor-pointer min-w-60 hover:bg-blue-500 group",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1),
    (_ctx.altLabel)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.altLabel), 1))
      : _createCommentVNode("", true)
  ]))
}