import { ActionTree, GetterTree, MutationTree } from "vuex";
import AutomationModel from "@/models/AutomationModel";
import { RootState, AutomationsState } from "../types";
import axios from "axios";

export default {
    namespaced: true,
    state: () => ({
        list: [],
        active: null,
        total: 0
    }),
    getters: {
        getById: state => (id: number) => {
            return state.list.find(automation => automation.id === id);
        },
        enabledList(state) {
            return state.list.filter(
                automation => automation.status === "enabled"
            );
        },
        disabledList(state) {
            return state.list.filter(
                automation => automation.status !== "enabled"
            );
        }
    } as GetterTree<AutomationsState, RootState>,
    mutations: {
        SET_LIST(state: AutomationsState, list: AutomationModel[]) {
            state.list = list;
        },
        SET_TOTAL(state: AutomationsState, total: number) {
            state.total = total;
        },
        SET_ACTIVE(
            state: AutomationsState,
            automation: AutomationModel | null
        ) {
            state.active = automation;
        },
        UPDATE_AUTOMATION_IN_LIST(
            state: AutomationsState,
            automation: AutomationModel
        ) {
            const index = state.list.findIndex(a => a.id === automation.id);
            if (index !== -1) {
                state.list[index] = automation;
            }
        },
        SET_ACTIVE_BY_ID(state: AutomationsState, id: number) {
            const automation = state.list.find(m => m.id === id);
            if (automation !== undefined) {
                state.active = { ...automation };
            }
        },
        CLEAR_ACTIVE(state: AutomationsState) {
            state.active = null;
        }
    } as MutationTree<AutomationsState>,
    actions: {
        fetchList: ({ commit }) => {
            return axios.get("api/automations").then(response => {
                commit("SET_LIST", Object.values(response.data.automations));
                commit("SET_TOTAL", response.data.total);
                return response.data;
            });
            // @TODO: Handle error case when we can't fetch the emails for any reason
        },
        fetchActive: ({ commit }, id: string | number) => {
            return axios.get(`api/automations/${id}`).then(response => {
                commit("SET_ACTIVE", response.data.automation);
                return response.data;
            });
            // @TODO: Handle error
        },
        activate: (
            { commit },
            {
                id,
                data
            }: {
                id: number | string;
                data: { status: string; wait_period?: number };
            }
        ) => {
            return axios
                .patch(`api/automations/${id}/edit`, data)
                .then(response => {
                    commit("SET_ACTIVE", response.data.automation);
                    commit(
                        "UPDATE_AUTOMATION_IN_LIST",
                        response.data.automation
                    );
                    return response;
                });
            // @TODO: Handle error
        }
    } as ActionTree<AutomationsState, RootState>
};
