
import { computed, defineComponent, PropType } from "vue";

enum ButtonSize {
    regular = "regular",
    large = "large"
}

enum ButtonStyle {
    primary = "primary",
    secondary = "secondary",
    white = "white",
    whiteActive = "whiteActive",
    transparent = "transparent",
    transparentUnderline = "transparentUnderline",
    breadcrumbStart = "breadcrumbStart",
    breadcrumb = "breadcrumb"
}

enum DisabledStyle {
    disabled = "disabled",
    lighter = "lighter"
}

export default defineComponent({
    name: "AppButton",
    props: {
        buttonStyle: {
            type: String as PropType<ButtonStyle>,
            default: ButtonStyle.primary,
            validator: (value: string) => {
                return Object.keys(ButtonStyle).indexOf(value) !== -1;
            }
        },
        disabledStyle: {
            type: String as PropType<DisabledStyle>,
            default: DisabledStyle.disabled,
            validator: (value: string) => {
                return Object.keys(DisabledStyle).indexOf(value) !== -1;
            }
        },
        buttonSize: {
            type: String as PropType<ButtonSize>,
            default: ButtonSize.regular,
            validator: (value: string) => {
                return Object.keys(ButtonSize).indexOf(value) !== -1;
            }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ["click"],
    setup(props, { emit, slots }) {
        const styles = {
            primary:
                "bg-blue-500 text-white border-blue-500 hover:bg-blue-700 hover:border-blue-700",
            secondary:
                "bg-light-2 text-dark-2 border-light-2 hover:bg-light-3 hover:border-light-3",
            white: "bg-white text-dark-2 border-white hover:border-dark-2",
            whiteActive:
                "bg-dark-3 border-dark-3 text-white hover:border-dark-2",
            transparent: "text-dark-2 border-transparent",
            transparentUnderline: "text-dark-2 underline border-transparent",
            breadcrumbStart: "text-dark-2 border-transparent",
            breadcrumb: "bg-light-1 text-dark-2 border-transparent"
        };
        const disabledStyles = {
            white: "bg-white text-light-3 border-white cursor-not-allowed",
            lighter:
                "bg-light-1 text-dark-2 text-opacity-20 border-light-1 cursor-not-allowed",
            disabled:
                "bg-light-2 text-dark-2 text-opacity-20 border-light-2 cursor-not-allowed"
        };
        const sizes = {
            regular: "leading-8 text-sm font-medium border",
            large: "leading-10 text-base font-medium border-none"
        };

        const isBreadcrumb = computed(() => {
            return props.buttonStyle == ButtonStyle.breadcrumbStart;
        });
        const hasLeft = computed(() => slots.left !== undefined);
        const hasRight = computed(() => slots.right !== undefined);

        const style = computed((): string => {
            return props.disabled
                ? disabledStyles[props.disabledStyle]
                : styles[props.buttonStyle];
        });

        const click = () => {
            if (!props.disabled) {
                emit("click");
            }
        };

        return {
            sizes,
            styles,
            hasLeft,
            hasRight,
            style,
            click,
            isBreadcrumb
        };
    }
});
