import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withModifiers as _withModifiers, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-block text-dark-2 leading-8 text-sm font-medium select-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "inline-flex justify-between items-center px-4 cursor-pointer w-full border border-dark-2 rounded-md bg-light-2 hover:bg-light-1",
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggle')))
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _withDirectives(_createVNode(_component_app_icon, {
      name: "ChevronUp",
      size: "5",
      class: "ml-4",
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
    }, null, 512), [
      [_vShow, _ctx.open]
    ]),
    _withDirectives(_createVNode(_component_app_icon, {
      name: "ChevronDown",
      size: "5",
      class: "ml-4",
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
    }, null, 512), [
      [_vShow, !_ctx.open]
    ])
  ]))
}