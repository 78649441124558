
import { defineComponent, computed, ref } from "vue";
import DropdownBase from "@/components/common/dropdown/DropdownBase.vue";
import DropdownButton from "@/components/common/dropdown/DropdownButton.vue";
import AppAvatar from "@/components/common/AppAvatar.vue";
import { useStore } from "vuex";
import AppIcon from "@/components/common/AppIcon.vue";

export default defineComponent({
    name: "DropdownMenu",
    components: {
        DropdownBase,
        DropdownButton,
        AppAvatar,
        AppIcon
    },
    props: {
        label: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "button"
        }
    },
    setup() {
        const store = useStore();

        const isOpen = ref(false);
        return {
            me: computed(() => store.state.user.me),
            isOpen
        };
    }
});
