const goToSupport = () => {
    const goToSupportPage = () => {
        window.open("https://maatoo.io/support", "_blank");
    };

    const openChat = () => {
        if (window.zE) {
            window.zE("webWidget", "show");
            window.zE("webWidget", "open");
        }
    };

    return {
        goToSupportPage,
        openChat
    };
};

export default goToSupport;
