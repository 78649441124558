import { ActionTree, GetterTree, MutationTree } from "vuex";
import { ContactsState, RootState } from "@/store/types";
import ContactModel from "@/models/ContactModel";
import axios from "axios";

export default {
    namespaced: true,
    state: () => ({
        list: [],
        total: 0
    }),
    getters: {} as GetterTree<ContactsState, RootState>,
    mutations: {
        SET_LIST(state: ContactsState, list: ContactModel[]) {
            state.list = list;
        },
        SET_TOTAL(state: ContactsState, total: number) {
            state.total = total;
        }
    } as MutationTree<ContactsState>,
    actions: {
        fetchList: ({ commit }, params) => {
            return axios
                .get("api/ecommerce/contacts", { params: params })
                .then(response => {
                    const contacts: ContactModel[] = Object.values(
                        response.data.contacts
                    );
                    const contactList = contacts.map(
                        (contact: ContactModel) => ({
                            ...contact,
                            fullName: contact.firstname + " " + contact.lastname
                        })
                    );
                    commit("SET_LIST", contactList);
                    commit("SET_TOTAL", parseInt(response.data.total));
                    return response.data;
                });
        }
    } as ActionTree<ContactsState, RootState>
};
