import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState, DashboardState } from "../types";
import axios from "axios";

export default {
    namespaced: true,
    state: () => ({
        is_fetched: false,
        bestSellingProduct: null,
        bestSellingProductOrderCount: 0,
        bestSellingProductOrderQuantity: 0,
        highestValueSegment: null,
        store_connected: false,
        brand_customized: false,
        legal_updated: false
    }),
    getters: {} as GetterTree<DashboardState, RootState>,
    mutations: {
        SET_ISFETCHED(state: DashboardState, is_fetched: boolean) {
            state.is_fetched = is_fetched;
        },
        SET_STATE(state: DashboardState, dashboardData: DashboardState) {
            state.bestSellingProduct = dashboardData.bestSellingProduct;
            state.bestSellingProductOrderCount =
                dashboardData.bestSellingProductOrderCount;
            state.bestSellingProductOrderQuantity =
                dashboardData.bestSellingProductOrderQuantity;
            state.highestValueSegment = dashboardData.highestValueSegment;
            state.store_connected = dashboardData.store_connected;
            state.brand_customized = dashboardData.brand_customized;
            state.legal_updated = dashboardData.legal_updated;
        }
    } as MutationTree<DashboardState>,
    actions: {
        fetchState: ({ commit }) => {
            return axios.get("api/dashboard").then(response => {
                const is_fetched = true;
                commit("SET_ISFETCHED", is_fetched);
                commit("SET_STATE", response.data);
                return response.data;
            });
        }
    } as ActionTree<DashboardState, RootState>
};
