import { ActionTree, GetterTree, MutationTree } from "vuex";
import UserModel from "@/models/UserModel";
import { RootState, UserState } from "../types";
import axios from "axios";
import md5 from "md5-ts";

export default {
    namespaced: true,
    state: () => ({
        me: null
    }),
    getters: {
        emailHash(state) {
            return state.me ? md5(state.me.email) : "";
        }
    } as GetterTree<UserState, RootState>,
    mutations: {
        SET_ME(state: UserState, me: UserModel) {
            state.me = me;
        }
    } as MutationTree<UserState>,
    actions: {
        fetchMe: ({ commit }) => {
            return axios.get("api/users/self").then(response => {
                commit("SET_ME", response.data);
            });
        }
    } as ActionTree<UserState, RootState>
};
