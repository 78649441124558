require("dotenv").config();
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/config/i18n";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { EventHint } from "@sentry/browser";
import mixinMobileDetection from "./mixins/mobile";
import "@/assets/styles/main.css";

const app = createApp(App);

Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    release: "maatoo-frontend@" + (process.env.VUE_APP_VERSION || "local"),
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        })
    ],
    beforeSend: (event, hint?: EventHint) => {
        if (process.env.NODE_ENV === "development" && hint) {
            console.error(hint.originalException || hint.syntheticException);
            return null;
        }

        return event;
    },
    tracesSampleRate: 0.1
});

app.use(store)
    .use(router)
    .use(i18n)
    .use(PrimeVue)
    .use(ToastService)
    .directive("tooltip", Tooltip)
    .mixin(mixinMobileDetection)
    .mount("#app");
