import axios from "axios";
import { Module, ActionTree, MutationTree } from "vuex";
import { AuthState, RootState } from "../types";

const REDIRECT_URI = window.location.origin + "/m/";

const ACCESS_TOKEN = "app_access_token";
const REFRESH_TOKEN = "app_refresh_token";

export default {
    namespaced: true,
    state: () => ({
        accessToken: localStorage.getItem(ACCESS_TOKEN) || "",
        refreshToken: localStorage.getItem(REFRESH_TOKEN) || "",
        clientId: "",
        clientSecret: "",
        baseUrl: "",
        beeEditorUid: "",
        release: 0,
        new_dashboard_enabled: false
    }),
    mutations: {
        REMOVE_ACCESS_TOKEN(state: AuthState) {
            state.accessToken = "";
            localStorage.removeItem("app_access_token");
        },
        REMOVE_REFRESH_TOKEN(state: AuthState) {
            state.refreshToken = "";
            localStorage.removeItem("app_refresh_token");
        },
        SET_ACCESS_TOKEN(state: AuthState, token: string) {
            state.accessToken = token;
            localStorage.setItem("app_access_token", token);
        },
        SET_REFRESH_TOKEN(state: AuthState, token: string) {
            state.refreshToken = token;
            localStorage.setItem("app_refresh_token", token);
        },
        SET_CLIENT_CONFIG(
            state: AuthState,
            {
                clientId,
                clientSecret,
                baseUrl,
                beeEditorUid,
                release,
                new_dashboard_enabled
            }: {
                clientId: string;
                clientSecret: string;
                baseUrl: string;
                beeEditorUid: string;
                release: number;
                new_dashboard_enabled: boolean;
            }
        ) {
            state.clientId = clientId;
            state.clientSecret = clientSecret;
            state.baseUrl = baseUrl;
            state.beeEditorUid = beeEditorUid;
            state.release = release;
            state.new_dashboard_enabled = new_dashboard_enabled;
        }
    } as MutationTree<AuthState>,
    actions: {
        fetchClientConfig: ({ commit }) => {
            return axios.get("nextgen/config").then(response => {
                commit("SET_CLIENT_CONFIG", {
                    clientId: response.data.publicKey,
                    clientSecret: response.data.secretKey,
                    baseUrl: response.data.baseUrl,
                    beeEditorUid: response.data.beeEditorUid,
                    release: response.data.release,
                    new_dashboard_enabled: response.data.new_dashboard_enabled
                });
            });
        },
        authorize: async ({ commit, dispatch, state }) => {
            commit("REMOVE_ACCESS_TOKEN");
            commit("REMOVE_REFRESH_TOKEN");
            if (!state.clientId) {
                await dispatch("fetchClientConfig");
            }
            const uniqueString = Date.now();
            window.location.replace(
                `${state.baseUrl}/oauth/v2/authorize?client_id=${
                    state.clientId
                }&grant_type=authorization_code&redirect_uri=${encodeURI(
                    REDIRECT_URI
                )}&response_type=code&state=${uniqueString}`
            );
        },
        fetchAccessToken: async ({ commit, dispatch, state }, code: string) => {
            if (!state.clientId) {
                await dispatch("fetchClientConfig");
            }
            const data = {
                grant_type: "authorization_code",
                client_id: state.clientId,
                client_secret: state.clientSecret,
                redirect_uri: REDIRECT_URI,
                code
            };

            return axios.post("oauth/v2/token", data).then(response => {
                commit("SET_ACCESS_TOKEN", response.data.access_token);
                commit("SET_REFRESH_TOKEN", response.data.refresh_token);
                return response.data.access_token;
            });
        },
        refresh: async ({ commit, dispatch, state }) => {
            if (!state.clientId) {
                await dispatch("fetchClientConfig");
            }
            const data = {
                grant_type: "refresh_token",
                client_id: state.clientId,
                client_secret: state.clientSecret,
                redirect_uri: REDIRECT_URI,
                refresh_token: state.refreshToken
            };

            return axios.post("oauth/v2/token", data).then(response => {
                commit("SET_ACCESS_TOKEN", response.data.access_token);
                commit("SET_REFRESH_TOKEN", response.data.refresh_token);
                return response.data.access_token;
            });
        },
        switchToClassic: ({ state }) => {
            window.open(state.baseUrl + "/s", "_blank");
        },
        openContactDetails: ({ state }, contactId: string | number) => {
            window.open(
                state.baseUrl + "/s/contacts/view/" + contactId,
                "_blank"
            );
        },
        signOut: ({ commit }) => {
            axios.get("api/logout").then(() => {
                axios.defaults.headers.common["Authorization"] = undefined;
                commit("REMOVE_ACCESS_TOKEN");
                commit("REMOVE_REFRESH_TOKEN");
                window.location.reload();
            });
        }
    } as ActionTree<AuthState, RootState>
} as Module<AuthState, RootState>;
