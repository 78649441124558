import { GetterTree, Module, MutationTree } from "vuex";
import { AppState, RootState } from "../types";

export default {
    namespaced: true,
    state: () =>
        ({
            loading: false,
            initialized: false,
            previousPath: ""
        } as AppState),
    getters: {
        previousPath: state => () => {
            return state.previousPath;
        }
    } as GetterTree<AppState, RootState>,
    mutations: {
        SET_LOADING(state: AppState, loading: boolean) {
            state.loading = loading;
        },
        SET_INITIALIZED(state: AppState, initialized: boolean) {
            state.initialized = initialized;
        },
        SET_PREVIOUS_PATH(state: AppState, previousPath: string) {
            state.previousPath = previousPath;
        }
    } as MutationTree<AppState>
} as Module<AppState, RootState>;
