const useUnicodeHelper = () => {
    const encodeUnicode = (str: string) => {
        return btoa(
            encodeURIComponent(str).replace(
                /%([0-9A-F]{2})/g,
                function toSolidBytes(match, p1) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    return String.fromCharCode("0x" + p1);
                }
            )
        );
    };

    const decodeUnicode = (str: string) => {
        return decodeURIComponent(
            atob(str)
                .split("")
                .map(function(c) {
                    return (
                        "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join("")
        );
    };

    return { encodeUnicode, decodeUnicode };
};

export default useUnicodeHelper;
