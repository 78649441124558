import { Directive } from "vue";
export default {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = (
            event: MouseEvent | TouchEvent | KeyboardEvent
        ) => {
            event.stopPropagation();
            const { handler, exclude } = binding.value;

            const elementClasses = (event.target as HTMLElement).className;

            let clickedOnExcludedEl = false;
            if (elementClasses.length) {
                for (const className of exclude) {
                    clickedOnExcludedEl = elementClasses.includes(className);
                    if (clickedOnExcludedEl) {
                        break;
                    }
                }
            }

            if (
                !(
                    el == event.target ||
                    el.contains(event.target) ||
                    clickedOnExcludedEl
                )
            ) {
                handler();
            }
        };
        document.addEventListener("click", el.clickOutsideEvent);
        document.addEventListener("touchstart", el.clickOutsideEvent);

        document.onkeydown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                binding.value();
            }
        };
    },
    unmounted: el => {
        document.removeEventListener("click", el.clickOutsideEvent);
        document.removeEventListener("touchstart", el.clickOutsideEvent);
        document.onkeydown = null;
    }
} as Directive;
